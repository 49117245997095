import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import GlobalLayout from "../components/global/GlobalLayout";
import ArticleBody from "../components/content/ArticleBody";
import { parseParameters } from "../includes/utils";
import { IContentTableOfContentsLink } from "../components/content/ContentTableOfContents";
import PageNotFound from "./404";

interface IPreviewProps {
  data: IPreviewQueryData;
}

interface IArticleResult {
  node: {
    id: string;
    articleData: {
      title: string;
      labels: string[];
      createdDate: string;
      readTime: number;
      anchors: IContentTableOfContentsLink[];
      bodyHtml: string;
    }
  }
}

const Preview = ({ data }: IPreviewProps): JSX.Element | null => {
  const [id, setID] = useState("");
  const { siteMetadata } = data.site;

  useEffect(() => {
    setID(parseParameters().id);
  }, []);

  if (id === "") {
    return null;
  } else if (!id) {
    return <PageNotFound/>;
  }

  const article: IArticleResult | undefined = data.allConfluencePage.edges.find((result: IArticleResult): boolean => result.node.id === id);
  if (!article) {
    return <PageNotFound error={`Could not find article with ID: ${id}`}/>;
  }

  const { articleData } = article.node;
  const { createdDate, bodyHtml } = articleData;

  return (
    <GlobalLayout siteMetadata={siteMetadata} smoothScroll={true}>
      <ArticleBody {...articleData} createdDate={new Date(createdDate)} html={bodyHtml}/>
    </GlobalLayout>
  );
};
export default Preview;

interface IPreviewQueryData {
  site: {
    siteMetadata: {
      name: string;
      description: string;
      hostname: string;
      auth: string;
    }
  }
  allConfluencePage: {
    edges: IArticleResult[]
  }
}

export const query = graphql`
  query PreviewQuery {
    site {
      siteMetadata {
        name
        description
        hostname
        auth
      }
    }
    allConfluencePage {
      edges {
        node {
          id
          articleData {
            title
            labels
            createdDate
            readTime
            anchors {
              slug
              title
              isChild
            }
            bodyHtml
          }
        }
      }
    }
  }
`;